import React from 'react'
import Layout from '../layouts'
import { Link } from 'gatsby'

const ThankYou = () => (
	<Layout head={{ title: 'Thank You' }}>
		<div className="banner-background">
			<center>
				<h1>Contact Us</h1>
			</center>
		</div>
		<center>
			<div className="thank-you container">
				<p>Thank you for reaching us.</p>
				<p>We have received your message and will get back to you soon.</p>
				<div className="form-group">
					<Link to="/" className="btn btn-primary btn-block">
						Back to Home
					</Link>
				</div>
			</div>
		</center>
	</Layout>
)

export default ThankYou
